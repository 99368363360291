import { AxiosRequestConfig } from 'axios';

import { IUserResponse } from './user';

export const REALTIME_CURRENT_WEATHER = 'Weather';
export const REALTIME_CURRENT_TIME = 'CurrentTime';

export const REALTIME_FORECAST_DAY_ONE = 'ForecastDay1';
export const REALTIME_FORECAST_DAY_TWO = 'ForecastDay2';
export const REALTIME_FORECAST_DAY_THREE = 'ForecastDay3';
export const REALTIME_FORECAST_ICON_ONE = 'ForecastIcon1';
export const REALTIME_FORECAST_ICON_TWO = 'ForecastIcon2';
export const REALTIME_FORECAST_ICON_THREE = 'ForecastIcon3';
export const REALTIME_FOREACST_LOW_ONE = 'ForecastLow1';
export const REALTIME_FOREACST_LOW_TWO = 'ForecastLow2';
export const REALTIME_FOREACST_LOW_THREE = 'ForecastLow3';
export const REALTIME_FOREACST_HIGH_ONE = 'ForecastHigh1';
export const REALTIME_FOREACST_HIGH_TWO = 'ForecastHigh2';
export const REALTIME_FOREACST_HIGH_THREE = 'ForecastHigh3';

export const REALTIME_FORECAST_TYPES = [
  REALTIME_FORECAST_DAY_ONE,
  REALTIME_FORECAST_DAY_TWO,
  REALTIME_FORECAST_DAY_THREE,
  REALTIME_FORECAST_ICON_ONE,
  REALTIME_FORECAST_ICON_TWO,
  REALTIME_FORECAST_ICON_THREE,
  REALTIME_FOREACST_LOW_ONE,
  REALTIME_FOREACST_LOW_TWO,
  REALTIME_FOREACST_LOW_THREE,
  REALTIME_FOREACST_HIGH_ONE,
  REALTIME_FOREACST_HIGH_TWO,
  REALTIME_FOREACST_HIGH_THREE,
];

export type TFileOrigin = 'upload' | 'default' | 'presto'

export type RealtimeDataType = typeof REALTIME_CURRENT_TIME | typeof REALTIME_CURRENT_WEATHER

export interface PrestoRealtimeFeed {
  realtimeDataType: RealtimeDataType
  setup: {
    size: {
      width: number
      height: number
    }
    position: {
      x: number
      y: number
      rotate?: number
    }
  }
  font: string,
  color: string,
  bold?: boolean,
  italic?: boolean,
  underline?: boolean,
  lineHeight?: number,
  opacity?: number,
  align?: 'right' | 'left' | 'center',
  startTime?: number,
  endTime?: number,
  textContent?: string,
  fontDownloadUrl?: string | null

  // types for realtime feed icon
  iconPack?: string
  iconPackUrl?: string
  url?: string
}

export interface PrestoRealtime {
  resolution: {
    width: number
    height: number
  }
  aspectRatio: string
  feeds: PrestoRealtimeFeed[]
}

export interface PrestoRealtimeWithMetadata extends PrestoRealtime {
  feeds: PrestoRealtimeFeed[]
}

export interface IFileResponse {
  id: number
  companyId?: number
  height: number
  folderId: number
  name: string
  fileType: string
  location: string
  url: string
  downloadUrl: string
  thumbnailUrl: string
  prestoId: string | null
  prestoRealtime: PrestoRealtime | null
  prestoRealtimeWithMetadata: PrestoRealtimeWithMetadata | null
  realtimeTypes: RealtimeDataType[]
  origin: TFileOrigin
  breadcrumbs: {id: number, name: string}[]
  addedBy?: number
  updatedAt: string
  createdAt: string
  accessedAt?: string
  deletedAt?: string | null
  file?: FormData
  user: IUserResponse | null
  width: number
  durationInMilliseconds: number | null
  usedAsDefaultContentCount: number
}

export interface IFileCreateRequest extends AxiosRequestConfig {
  data: FormData
}

export interface IFileUploadForm {
  file: File
  origin?: TFileOrigin
}

export interface IFilesUploadForm {
  files: File[]
  origin?: TFileOrigin
}
